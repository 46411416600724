import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Link from 'gatsby-link';
import TemplateWrapper from '../components/layout';

class SiteMap extends Component {
  render() {
    return (
      <TemplateWrapper>
      <div className="article-content">
        <Helmet>
          <title>Карта сайта</title>
          <meta name="description" content="Не потеряйтесь на сайте лучше студии наращивания волос в Москве" />
          <meta name="keywords" content="Поиск, нарощенные волосы, окрасить волосы" />
        </Helmet>
 
          <h1 className="hm-title-1">Карта сайта</h1>
          <div className="row">
            <div className="col-md-3">
              <ul className="hm-list">
                <li><Link to="/">Главная</Link></li>
                <li><Link to="/nashi-raboty">Наши работы</Link></li>
                <li><Link to="/perezvonite-mne">Перезвоните мне</Link></li>
                <li><Link to="/prajs">Прайс лист</Link></li>
                <li><Link to="/karta-saita">Карта сайта</Link></li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="hm-list">
                <li>
                  <Link to="/narashchivanie-volos">Наращивание волос</Link>
                  <ul className="hm-list">
                    <li><Link to="/narashchivanie-volos/gollivudskoe">Голливудское наращивание</Link></li>
                    <li><Link to="/narashchivanie-volos/holodnoe">Холодное наращивание</Link></li>
                    <li><Link to="/narashchivanie-volos/lentochnoe">Ленточное наращивание</Link></li>
                    <li><Link to="/narashchivanie-volos/stoimost">Стоимость наращивания</Link></li>
                    <li><Link to="/narashchivanie-volos/dlina">Как выбрать длину?</Link></li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="hm-list">
                <li><Link to="/okrashivanie-volos">Окрашивание волос</Link></li>
              </ul>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
        </TemplateWrapper>
    );
  }
}

export default SiteMap;